<header class="header">
  <app-global-style></app-global-style>
  <div class="logo-container">
    <a href="/" class="logo">
      <img [src]="pageLogo || '/assets/images/logo.png'" height="35">
    </a>
  </div>
  <div class="header-right">
    <div *ngIf="user" id="userbox" class="userbox" #userbox
         (click)="toggleOpenClass($event, userbox)"
         (clickOutside)="removeOpenClass($event, userbox)">
      <a>
        <div class="profile-info">
          <span class="name">{{user?.username}}</span>
        </div>

        <i class="fa custom-caret"></i>
      </a>

      <div class="dropdown-menu animated fadeIn">
        <ul class="list-unstyled">
          <li class="name">{{user?.username}}</li>
          <li class="divider"></li>
          <li class="entry" *ngFor="let entry of userMenu">
            <a [routerLink]="entry?.path">
              <i class="fa {{entry?.icon}}"></i>
              <span>{{translateUserMenuEntry(entry?.name)}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="localebox" *ngIf="hasLocaleSupport">
      <app-locale-select></app-locale-select>
    </div>
  </div>
</header>

<span class="loading-overlay light" #layoutPreloader>
    <div class="load-container load8" style="font-size: 20px;text-align: center;">
      <div class="loader1"></div>
      <label class="preloader-label">{{preloaderLabel}}</label>
    </div>
</span>

<div id="wrapper" class="toggled" #menuWrapper>
  <div id="sidebar-wrapper">
    <div class="sidebar-toggle" (click)="toggleMenu($event, menuWrapper)">
      <i class="fa fa-bars"></i>
    </div>
    <ul class="sidebar-nav">
      <li class="sidebar-brand" [routerLink]="'/'">
        {{_('Navigation')}}
      </li>
      <ng-container *ngIf="isMenuVisible">
        <li *ngFor="let entry of menu" class="main" #li (click)="toggleOpenClass($event, li)" routerLinkActive="open">
          <a routerLinkActive="active">
            <div [routerLink]="entry?.path || '/none'" [ngClass]="{nofollow: entry?.children}" class="link">
              <i class="fa {{entry?.icon}}"></i>
              <span>{{translateMainMenuEntry(entry.name)}}</span>
              <i class="fa fa-arrow" *ngIf="entry?.children"></i>
            </div>
          </a>
          <ul class="sub">
            <li *ngFor="let subEntry of entry?.children" class="sub" (click)="$event.stopPropagation()" [ngClass]="{'sub-sub': subEntry?.sub}">
              <a [routerLink]="subEntry?.path" routerLinkActive="active">
                <i class="fa {{subEntry?.icon}}"></i>
                <span>{{translateMainMenuEntry(entry.name, subEntry?.name)}}</span>
              </a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>

    <div class="show-version"><span>
      <ng-container *ngIf="!versionLink">{{version}}</ng-container>
      <ng-container *ngIf="versionLink">
        <span style="display:inline;cursor: pointer;"[routerLink]="versionLink">{{version}}</span>
      </ng-container>
    </span>
    </div>
  </div>

  <div>
    <div class="content-header">
      <h2 *ngIf="activeMenuEntry">{{translateBreadcrumbEntry(activeMenuEntry)}}</h2>
      <div class="page-breadcrumb">
        <ol>
          <li>
            <a routerLink="/">
              <i class="fa fa-home"></i>
            </a>
          </li>

          <li *ngIf="activeMenuEntry">
            <span>{{translateBreadcrumbEntry(activeMenuEntry)}}</span>
          </li>
        </ol>
      </div>
    </div>

    <div id="page-content-wrapper">
      <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
    </div>
  </div>
</div>
